import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { UserInformation } from '../models/user-information';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public LoggedUserSubject: BehaviorSubject<any>;
  public userType: BehaviorSubject<Number>;
  public adminManageType: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(
    +localStorage.getItem('adminManageType') || 1
  )
  constructor(private http: HttpClient) {
    this.userType = new BehaviorSubject<Number>(
      +(localStorage.getItem('userType') || '0')
    )
    this.LoggedUserSubject = new BehaviorSubject<UserInformation>(
      JSON.parse(localStorage.getItem('loggedUser') || 'null')
    );
  }

  public get LoggedUserValue(): UserInformation {
    return this.LoggedUserSubject.value;
  }

  public get userTypeValue(): Number {
    return this.userType.value;
  }

  CTLogin(form: any) {
    return this.http.post(`${environment.ctUrl}Client/Login`, form)
  }

  logout() {
    this.LoggedUserSubject.next(null);
    this.adminManageType.next(null);
    localStorage.clear();
  }

  CTResetPassword(payload: any) {
     ;
    return this.http.post<any>(`${environment.ctUrl}Settings/ResetPassword`, payload)
  }

  CTForgotPassword(email: string) {
    return this.http.post<any>(`${environment.ctUrl}Settings/ForgotPassword/${email}`, null);
  }
  

}
